import { onDarkBase } from '@biom3/design-tokens';
import { themes } from './theme-config';
import { InputTheme, StorefrontTypes } from './types';

export const getTheme = (storefront: StorefrontTypes): InputTheme => {
  if (storefront in themes) {
    return themes[storefront];
  }

  return { base: onDarkBase };
};
