import { DesignTokens } from '@biom3/design-tokens';
import { BiomeTheme } from '@biom3/react';

export type InputTheme = DesignTokens & Pick<BiomeTheme, 'components'>;

export enum StorefrontTypes {
  // eslint-disable-next-line no-unused-vars
  METALCORE = 'metalcore',
  // eslint-disable-next-line no-unused-vars
  SHARDBOUND = 'shardbound',
  // eslint-disable-next-line no-unused-vars
  GOG = 'gog',
  // eslint-disable-next-line no-unused-vars
  SAMPLE_STORE = 'sample-store'
}
