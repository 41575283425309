'use client';

import { BiomeCombinedProviders } from '@biom3/react';
import { getTheme } from '../theme';
import { storefrontType } from '../utils/env';

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const storefrontTheme = getTheme(storefrontType);

  return <BiomeCombinedProviders theme={storefrontTheme}>{children}</BiomeCombinedProviders>;
}
