export function notifyError(error: any) {
  console.info('notifyError', error);

  if (typeof window === 'undefined') {
    try {
      const newrelic = require('newrelic');
      newrelic.noticeError(error);
    } catch (error) {
      console.warn({ error });
    }
    // @ts-ignore
  } else if (window?.newrelic?.noticeError !== undefined) {
    // @ts-ignore
    window.newrelic.noticeError(error);
  }
}
