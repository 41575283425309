import {
  StandardAnalyticsActions,
  StandardAnalyticsControlTypes,
  createAnalytics
} from '@imtbl/react-analytics';
import { analyticsConfig } from '../utils';

type UserJourney = 'ViewPage' | 'ViewProduct' | 'WalletConnected' | 'PrimarySale';
type Control = string;
type ControlTypes = StandardAnalyticsControlTypes | 'Event';
type Screen = string;
type Actions = StandardAnalyticsActions | 'Confirmation';

const { appName, writeKey } = analyticsConfig;
export const { AnalyticsProvider, useAnalytics } = createAnalytics<
  UserJourney,
  Screen,
  Control,
  ControlTypes,
  Actions
>({ writeKey, appName });
