import { DeeplyNestedSx } from '@biom3/react';
import { fontLitmuseum } from './fonts-config';

export const metalcoreButtonSxOverride: DeeplyNestedSx = {
  '&.Button--primary': {
    bg: 'transparent',
    boxShadow: 'unset',
    fontWeight: 'bold',

    '& .Icon': {
      fill: 'base.color.text.body.inverse.primary'
    },

    '&.Button--large': {
      px: 'base.spacing.x17'
    },

    '&.Button--medium': {
      px: 'base.spacing.x9'
    },

    '&.Button--small': {
      px: 'base.spacing.x6'
    },

    '&::before': {
      bg: 'base.color.accent.2',
      clipPath: 'unset'
    },

    '&.passport::before': {
      bg: 'base.color.fixed.white.1000'
    },

    '&:hover:not([disabled])': {
      boxShadow: 'unset',

      '&::before': {
        bg: 'base.color.accent.1',
        clipPath: 'unset'
      }
    },

    '&:active:not([disabled])': {
      boxShadow: 'unset',

      '&::before': {
        bg: 'base.color.accent.8',
        clipPath: 'unset'
      }
    }
  },

  '&.Button--secondary': {
    boxShadow: 'unset',
    borderRadius: '0',
    background: 'base.color.translucent.inverse.700',

    '&::before': {
      clipPath: 'unset',
      borderRadius: '0'
    },

    '&:hover:not([disabled])': {
      color: 'base.color.text.body.primary',
      background: 'base.color.translucent.emphasis.100',
      boxShadow: 'unset',
      '&::before': {
        clipPath: 'unset'
      }
    },

    '&:active:not([disabled])': {
      color: 'base.color.text.body.primary',
      background: 'base.color.translucent.emphasis.100',

      boxShadow: 'unset',
      '&::before': {
        clipPath: 'unset'
      }
    }
  }
};

export const gogButtonSxOverride: DeeplyNestedSx = {
  '&.Button--primary': {
    brad: '0',
    fontWeight: 'bold',
    color: 'base.color.brand.6',
    background: 'base.color.brand.6',
    clipPath: 'polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%)',
    transition: 'background 100ms ease-in-out, color 100ms ease-in-out',
    fontFamily: fontLitmuseum.style.fontFamily,

    '&::before': {
      background: 'linear-gradient(180deg, #FAEFC8 8%, #FBDF9C 50%, #FECC66 90%)',
      clipPath: 'polygon(5.3% 6%, 94.7% 6%, 99% 50%, 94.7% 93%, 5.3% 93%, 1% 50%)'
    },

    '& .BiomeButton__inner': {
      width: '100%',

      '&::before, &::after': {
        content: '""',
        height: '0.5rem',
        width: '0.5rem',
        background: '#684d22',
        display: 'block',
        transform: 'rotate(45deg)',
        position: 'absolute'
      },
      '&::before': {
        left: '0'
      },
      '&::after': {
        right: '0'
      }
    },

    '&:hover:not([disabled])': {
      boxShadow: 'unset',
      color: '#684d22'
    },

    '&:active:not([disabled])': {
      boxShadow: 'unset',
      color: '#281e0d',
      background: '#684d22',

      '&::before': {
        background: 'linear-gradient(180deg, #FAEFC8 8%, #FBDF9C 50%, #FECC66 90%)',
        clipPath: 'polygon(5.3% 6%, 94.7% 6%, 99% 50%, 94.7% 93%, 5.3% 93%, 1% 50%)'
      },
      '& .BiomeButton__inner::before, & .BiomeButton__inner::after': {
        background: '#281e0d'
      }
    }
  },
  '&.Button--secondary': {
    brad: '0',
    boxShadow: 'unset',
    background: 'base.color.translucent.inverse.700',
    transition: 'background 100ms ease-in-out, color 100ms ease-in-out',

    '&::before': {
      background: 'transparent'
    },

    '&:hover:not([disabled])': {
      boxShadow: 'unset',
      background: 'base.color.translucent.standard.500',

      '&::before': {
        boxShadow: 'unset',
        clipPath: 'none',
        background: 'transparent'
      }
    },

    '&:active:not([disabled])': {
      background: 'base.color.translucent.standard.600'
    }
  }
};
