/* eslint-disable no-unused-vars */
import { checkout as ImtblCheckout, passport as ImtblPassport } from '@imtbl/sdk';
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';

import { Web3Provider } from '@ethersproject/providers';
import { useCheckoutClient } from 'src/hooks/use-checkout-client';
import { UserInfo, usePassportClient } from 'src/hooks/use-passport-client';
import { environment, lsWalletProviderName, notifyError, walletConnectConfig } from '../utils';
import { AtLeastOne } from '../utils/types';

type Widgets = {
  saleWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.SALE>;
  walletWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.WALLET>;
  swapWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.SWAP>;
  bridgeWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.BRIDGE>;
  onRampWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.ONRAMP>;
  connectWidget: ImtblCheckout.Widget<typeof ImtblCheckout.WidgetType.CONNECT>;
};

type WidgetsState = {
  passport: ImtblPassport.Passport | undefined;
  checkout: ImtblCheckout.Checkout | undefined;
  provider: Web3Provider | undefined;
  factory: ImmutableCheckoutWidgets.WidgetsFactory | undefined;
  walletProviderName: ImtblCheckout.WalletProviderName | undefined;
  userInfo: UserInfo;
} & Partial<Widgets>;

type SetProviderPayload = {
  type: 'SET_PROVIDER';
  provider: WidgetsState['provider'];
  walletProviderName?: WidgetsState['walletProviderName'];
};

type SetFactoryPayload = {
  type: 'SET_FACTORY';
  factory: WidgetsState['factory'];
};

type SetWalletProviderNamePayload = {
  type: 'SET_WALLET_PROVIDER_NAME';
  walletProviderName: WidgetsState['walletProviderName'];
};

type SetWidgetPayload = {
  type: 'SET_WIDGET';
  widget: AtLeastOne<Widgets>;
};

type SetPassport = {
  type: 'SET_PASSPORT';
  passport: WidgetsState['passport'];
};

type SetCheckout = {
  type: 'SET_CHECKOUT';
  checkout: WidgetsState['checkout'];
};

type SetUserInfo = {
  type: 'SET_USER_INFO';
  userInfo: UserInfo;
};

type WidgetsActionPayload =
  | SetProviderPayload
  | SetFactoryPayload
  | SetWalletProviderNamePayload
  | SetWidgetPayload
  | SetPassport
  | SetCheckout
  | SetUserInfo;

type WidgetsAction = {
  payload: WidgetsActionPayload;
};

type WidgetsContextState = {
  state: WidgetsState;
  dispatch: Dispatch<WidgetsAction>;
};

export const intialWidgetsState: WidgetsState = {
  provider: undefined,
  passport: undefined,
  checkout: undefined,
  factory: undefined,
  walletProviderName: undefined,
  saleWidget: undefined,
  walletWidget: undefined,
  swapWidget: undefined,
  bridgeWidget: undefined,
  onRampWidget: undefined,
  connectWidget: undefined,
  userInfo: undefined
};

export const WidgetsContext = createContext<WidgetsContextState>({
  state: intialWidgetsState,
  dispatch: () => {}
});

WidgetsContext.displayName = 'WidgetsContext';

type Reducer<S, A> = (prevState: S, action: A) => S;

const widgetsReducer: Reducer<WidgetsState, WidgetsAction> = (state, action) => {
  switch (action.payload.type) {
    case 'SET_PROVIDER':
      const withWalletProviderName = 'walletProviderName' in action.payload;

      if (withWalletProviderName) {
        lsWalletProviderName.write(action.payload.walletProviderName);
      }

      return {
        ...state,
        provider: action.payload.provider,
        ...(withWalletProviderName && {
          walletProviderName: action.payload.walletProviderName
        })
      };
    case 'SET_FACTORY':
      return { ...state, factory: action.payload.factory };
    case 'SET_WALLET_PROVIDER_NAME':
      lsWalletProviderName.write(action.payload.walletProviderName);
      return { ...state, walletProviderName: action.payload.walletProviderName };
    case 'SET_WIDGET':
      return { ...state, ...action.payload.widget };
    case 'SET_PASSPORT':
      return { ...state, passport: action.payload.passport };
    case 'SET_CHECKOUT':
      return { ...state, checkout: action.payload.checkout };
    case 'SET_USER_INFO':
      return { ...state, userInfo: action.payload.userInfo };
    default:
      return state;
  }
};

export const useWidgetsValue = (overrides: Partial<WidgetsState> = {}) => {
  const [state, dispatch] = useReducer(widgetsReducer, { ...intialWidgetsState, ...overrides });
  const values = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return values;
};

export const useWidgets = () => {
  const context = useContext(WidgetsContext);
  if (context === undefined) {
    const error = new Error('useWidgets must be used within a WidgetsContext.Provider');
    notifyError(error);
    throw error;
  }
  return [context.state, context.dispatch] as const;
};

export const WidgetsProvider = ({ children }: { children: ReactNode }) => {
  const widgetsValue = useWidgetsValue();
  const {
    state: { factory, provider, checkout },
    dispatch
  } = widgetsValue;
  const passportInstance = usePassportClient({ environment });
  const checkoutInstance = useCheckoutClient({ passport: passportInstance, environment });

  useEffect(() => {
    // create widgets factory and set passport & checkout instances
    if (!dispatch || !passportInstance || !checkoutInstance) return;

    (async () => {
      try {
        const widgetsFactory = await checkoutInstance.widgets({
          config: {
            theme: ImtblCheckout.WidgetTheme.DARK,
            walletConnect: walletConnectConfig
          }
        });
        dispatch({ payload: { type: 'SET_FACTORY', factory: widgetsFactory } });
        dispatch({ payload: { type: 'SET_PASSPORT', passport: passportInstance } });
        dispatch({ payload: { type: 'SET_CHECKOUT', checkout: checkoutInstance } });
      } catch (err) {
        notifyError(err);
      }
    })();
  }, [dispatch, passportInstance, checkoutInstance]);

  useEffect(() => {
    // forecast provider changed after reconnecting wallet
    if (!factory || !factory.updateProvider || !provider) return;
    factory.updateProvider(provider);
  }, [factory, provider]);

  useEffect(() => {
    // update user info if after provider changed to passport wallet
    if (!(provider?.provider as any)?.isPassport || !passportInstance) return;

    (async () => {
      const userInfo = await passportInstance.getUserInfo();
      dispatch({ payload: { type: 'SET_USER_INFO', userInfo } });
    })();
  }, [provider, passportInstance, dispatch]);

  return <WidgetsContext.Provider value={widgetsValue}>{children}</WidgetsContext.Provider>;
};
