import * as crypto from 'crypto';
import {
  PRICING_FIXED_DECIMALS,
  PRICING_MAXIMUN_FIXED,
  PRICING_MINIMUM_SIGNIFICANT_DIGITS,
  storefrontType
} from './env';
import { notifyError } from './errors';

export const isEmpty = (obj?: Record<string, any>) =>
  obj === null || obj === undefined || Object.keys(obj).length === 0;

export const safeJsonParse = (json: string) => {
  if (!json) return {};

  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};

export const delay = (seconds: number) =>
  new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const truncate = (address: string, length = 5) => {
  const start = address.slice(0, length);
  const end = address.slice(-length);
  return `${start}...${end}`;
};

export const readLocalStorage = (key: string) => {
  if (key === undefined) return '';

  try {
    const value = localStorage.getItem(key);
    return value ? safeJsonParse(value) : undefined;
  } catch (e) {
    typeof window !== undefined && notifyError(e);
  }
};

export const writeLocalStorage = (key: string, value: any) => {
  try {
    if (key === undefined) {
      notifyError(new Error('writeLocalStorage: key is undefined'));
      return;
    }

    if (value === undefined) {
      return localStorage.removeItem(key);
    }

    const safeValue = JSON.stringify(value);
    localStorage.setItem(key, safeValue);
  } catch (e) {
    typeof window !== undefined && notifyError(e);
  }
};

export const localStorageValue = (_key: string) => {
  const key = `@imtbl/commerce-${storefrontType}-${_key}`;
  return {
    write: (value: any) => writeLocalStorage(key, value),
    read: () => readLocalStorage(key),
    clear: () => writeLocalStorage(key, undefined)
  };
};

export const lsWalletProviderName = localStorageValue('wallet-provider-name');
export const lsReconnectingWallet = localStorageValue('reconnecting-wallet');

// create an md5 hash from a string and return a string
export const md5Hash = (buffer: string) => crypto.createHash('md5').update(buffer).digest('hex');

export const sanitizeToLatin1 = (str: string): string => {
  // match characters outside Latin1 (ISO-8859-1) range
  const regex = /[^\u0000-\u00FF]/g;
  return str.replace(regex, '');
};

export const toFormatedCurrency = (
  value: number | bigint | string,
  minimumFractionDigits = PRICING_FIXED_DECIMALS,
  minimumSignificantDigits = PRICING_MINIMUM_SIGNIFICANT_DIGITS
) => {
  const formatValue = getLastKNoneZeroDigits(
    Number(value).toFixed(PRICING_MAXIMUN_FIXED),
    PRICING_MINIMUM_SIGNIFICANT_DIGITS
  );

  const integer = formatValue.toString().split('.')[0] || 0;
  const fraction = formatValue.toString().split('.')[1] || 0;

  const maximumFractionDigits = fraction.toString().length || minimumFractionDigits;

  const maximumSignificantDigits =
    integer === 0 ? minimumSignificantDigits : integer.toString().length + minimumFractionDigits;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits,
    minimumSignificantDigits,
    minimumFractionDigits,
    maximumFractionDigits
  })
    .format(typeof formatValue === 'string' ? parseFloat(formatValue) : formatValue)
    .replace('$', '');
};

/**
 * Extracts the last `k` non-zero digits from the decimal part of a given number string.
 * usage:
 * - `getLastKNoneZeroDigits("0.0012345", 3)` returns `0.00123`
 * - `getLastKNoneZeroDigits("0.0012345", 2)` returns `0.0012`
 */
const getLastKNoneZeroDigits = (numStr: string, k: number): number => {
  const decimalIndex = numStr.indexOf('.');

  if (decimalIndex === -1) {
    return parseFloat(numStr);
  }

  const decimalPart = numStr.slice(decimalIndex + 1);
  const firstNonZeroIndex = decimalPart.search(/[1-9]/);

  if (firstNonZeroIndex === -1) {
    return parseFloat(numStr);
  }

  const result = numStr.slice(0, decimalIndex + 1 + firstNonZeroIndex + k);

  return parseFloat(result);
};
