export function recordMetric(name: string, value: number | Record<string, any>) {
  // console.info('web-vitals', name, value);

  if (typeof window === 'undefined') {
    try {
      const newrelic = require('newrelic');
      newrelic.recordMetric(name, value);
    } catch (error) {
      console.warn({ error });
    }
    // @ts-ignore
  } else if (window?.newrelic?.recordMetric !== undefined) {
    // @ts-ignore
    window.newrelic.recordMetric(name, value);
  }
}
